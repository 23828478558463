import phoneFilter from "@/helpers/filters/phoneFilter";
import {dictionariesHelper} from "@/helpers/dictionariesHelper";

const tableColsChildren = [
  {
    text: "Воспитанник",
    renderFunction: value => `${value.name}`
  },
  {
    text: "Табельный номер",
    value: "number",
    sort: true
  },
  {
    text: "№/название группы",
    value: "children_group",
    renderFunction: value => {
      return `${value.children_group.number}/ ${value.children_group.name}`;
    },
    sort: true
  },
  {
    text: "Опекуны",
    renderFunction: value => {
      return value?.parents_details
          ?.map(el => {
            if (el.additional_fields) {
              return (
                  phoneFilter.execute(el.phone_number) +
                  ", " +
                  el.additional_fields[0].value +
                  ", " +
                  el.additional_fields[1].value +
                  ", " +
                  " (" +
                  `${
                      dictionariesHelper.accountsStatus.userAuth[
                          el.is_phone_confirmed
                          ]
                  }` +
                  ")"
              );
            }
          })
          ?.join("\n");
    }
  }
];

export default tableColsChildren;
